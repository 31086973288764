import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';
import Spinner, { SpinnerColor, SpinnerSize } from 'shared-components/Spinner';

import Home from 'features/home';

import './App.css';

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Suspense fallback={<Spinner color={SpinnerColor.BLUE} size={SpinnerSize.LARGE} isCenter={true} />}>
                    <Switch>
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                </Suspense>
            </Router>
        </ThemeProvider>
    );
};

export default App;
