import React, { FC, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    Paper,
    Typography,
    Box,
    Button,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormHelperText,
    TextField,
    Divider,
} from '@material-ui/core';
import CopyRight from 'shared-components/CopyRight';
import colors from 'colors';
import { getWogaaDomainUserId } from './../../utils/utils';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 60,
        backgroundColor: colors.lightGrey,
    },
    innerContainer: {
        padding: '40px 56px',
        height: 'calc(100% - 98px)',
        width: 'calc(100% - 120px)',
        // maxWidth: 797,
        [theme.breakpoints.down('sm')]: {
            padding: '28px',
        },
    },
    logger: {
        width: '100%',
        height: '500px',
        border: `1px solid ${colors.lightGrey}`,
        padding: '10px',
        marginTop: 8,
        whiteSpace: 'pre',
        overflowY: 'auto',
    },
    error: {
        color: colors.red,
    },
}));

declare global {
    interface Window {
        personalise: any;
    }
}

type Environment = 'test' | 'staging' | 'production';

const scriptUrl = {
    test: 'https://test.percy.dcube.cloud/personalise.js',
    staging: 'https://staging.percy.dcube.cloud/personalise.js',
    production: 'https://percy.wogaa.sg/personalise.js',
};

const loadScript = (env: Environment) => {
    const url = scriptUrl[env];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = !0;
    script.src = url;
    const other = document.getElementsByTagName('script')[0];
    if (other && other.parentNode) {
        other.parentNode.insertBefore(script, other);
    }
};

const Home: FC = () => {
    const classes = useStyles();
    const [env, setEnv] = React.useState<Environment>('staging');
    const [logs, setLogs] = React.useState<string[]>([]);
    const [trackingUrl, setTrackingUrl] = React.useState<string>('');
    const [wogaaDomainUserId, setWogaaDomainUserId] = React.useState<string>('');

    useEffect(() => {
        loadScript(env);
        // eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     setWogaaDomainUserId(getWogaaDomainUserId() || '');
    // }, []);

    const handleChange = (evt: any) => {
        window.personalise = undefined;
        const env = evt.target.value;
        setEnv(env);
        loadScript(env);
    };

    const handleClick = () => {
        window.personalise.track((data: number) => {
            if (data === 204) {
                setLogs([...logs, 'Percy click event send']);
            } else {
                setLogs([...logs, 'Error in sending event']);
            }
        });
    };

    useEffect(() => {
        setTimeout(() => {
            const { trackingUrl, wogaaDomainUserId } = window.personalise || {};
            setTrackingUrl(trackingUrl);
            setWogaaDomainUserId(wogaaDomainUserId || '');
        }, 1000);
    }, [env]);

    return (
        <div className={classes.container}>
            <Paper className={classes.innerContainer} elevation={1}>
                <Box textAlign="left">
                    <Typography variant="h1" style={{ paddingBottom: 24 }}>
                        Playground Wogaa
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <FormControl component="fieldset" style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormLabel component="legend">Environment</FormLabel>
                            <RadioGroup
                                aria-label="gender"
                                name="gender1"
                                value={env}
                                onChange={handleChange}
                                style={{ display: 'flex', flexDirection: 'row' }}
                            >
                                <FormControlLabel value="test" control={<Radio />} label="Test" />
                                <FormControlLabel value="staging" control={<Radio />} label="Staging" />
                                <FormControlLabel value="production" control={<Radio />} label="Production" />
                            </RadioGroup>
                            <FormHelperText>
                                <span style={{ fontWeight: 'bold', paddingRight: 5 }}>Tracking Url:</span>{' '}
                                {trackingUrl ? trackingUrl : <span className={classes.error}>Error</span>}
                            </FormHelperText>
                        </FormControl>
                        <form noValidate autoComplete="off">
                            <FormLabel component="legend">
                                Wogaa Domain User Id <small>(wogaaDomainUserId)</small>
                            </FormLabel>
                            <TextField
                                placeholder="wogaaDomainUserId"
                                variant="outlined"
                                aria-readonly={true}
                                value={wogaaDomainUserId}
                            />
                        </form>
                    </div>
                    <Divider style={{ marginTop: 32 }} />
                    <div style={{ display: 'flex', marginTop: 24, flexDirection: 'column' }}>
                        <FormLabel component="legend">Track Events</FormLabel>
                        <div>
                            <Button
                                color="primary"
                                variant={'outlined'}
                                style={{ marginRight: 16 }}
                                disabled={!wogaaDomainUserId || !trackingUrl}
                                onClick={handleClick}
                            >
                                Click Event
                            </Button>
                        </div>
                    </div>
                    <FormLabel component="legend" style={{ marginTop: 24 }}>
                        Logs
                    </FormLabel>
                    <div className={classes.logger}>{<>{logs.join('\n')}</>}</div>
                </Box>
            </Paper>
            <CopyRight />
        </div>
    );
};

export default Home;
